@import "@assets/scss/variables.scss";
@import "@assets/scss/mixins.scss";

.item {
  width: 260px;
  aspect-ratio: 1/1.66;
  color: $light-color;
  cursor: pointer;
  h4 {
    z-index: 2;
  }
  a {
    color: $light-color;
  }
  img {
    object-fit: cover;
  }
  &::after,
  &::before {
    @extend %after-before;
    width: 100%;
    height: 50%;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, $font-color 0%, rgba($font-color, 0) 100%);
    z-index: 1;
  }
  &::after {
    top: 100%;
    bottom: auto;
    background: linear-gradient(0deg, $theme-color 0%, rgba($theme-color, 0) 100%);
    z-index: 1;
  }
  &:hover::after {
    top: 50%;
  }
}
.storyCount {
  z-index: 3;
}
.count {
  height: 3px;
  background: $light-color;
}

@media (max-width: 1199px) {
  .item {
    width: 180px;
  }
}
@media (max-width: 575px) {
  .item {
    width: 170px;
  }
}
